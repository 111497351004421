import { Directive, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { omitBy, isNull, isUndefined, cloneDeep, mergeWith } from 'lodash';

@Directive({
  selector: '[appBindQueryParams]'
})
export class BindQueryParamsDirective implements OnChanges {
  @Input('appBindQueryParams') queryParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnChanges(): void {
    const data = cloneDeep(this.queryParams);
    const mergedData = mergeWith({}, this.activatedRoute.snapshot.queryParams, data, (a, b) => (b === null ? b : undefined));
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { ...omitBy(mergedData, isNull) }
    });
  }
}
