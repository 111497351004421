import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertsService } from '@app/@shared/services/alerts.service';
import { Alerts } from '@app/@shared/fixtures/alerts';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(public alertsService: AlertsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) log.error('Request error', response);

    const config = (() => {
      switch (response?.['status']) {
        case 322:
          return {
            type: Alerts.INFO,
            message: `You do not have access to features on this page. ( ${response?.['error']?.Message} )`,
            dismissable: true
          };
        // case 403:
        //   return response;
        case 503:
          return {
            type: Alerts.WARNING,
            message: `If you are experiencing issues, the server may be over capacity. Please try again. (503)`,
            dismissable: true
          };
      }
    })();
    AlertsService.showAlert(config);
    throw response;
  }
}
