import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Modules
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AuthModule } from '@app/auth';
import { SharedModule } from '@app/@shared';

// Components
import { ShellComponent } from './shell.component';

@NgModule({
  imports: [LoadingBarRouterModule, AuthModule, SharedModule, RouterModule],
  declarations: [ShellComponent]
})
export class ShellModule {}
