import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from './auth';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'bids', loadChildren: () => import('./modules/browse-bids/browse-bids.module').then(m => m.BrowseBidsModule) },
    { path: 'bid', loadChildren: () => import('./modules/bid/bid.module').then(m => m.BidModule) },
    { path: 'publishers', loadChildren: () => import('./modules/publishers/publishers.module').then(m => m.PublishersModule) },
    { path: 'bid-forecasts', loadChildren: () => import('./modules/bid-forecasts/bid-forecasts.module').then(m => m.BidForecastsModule) },
    { path: 'terms', loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule) },
    { path: 'privacy', loadChildren: () => import('./modules/privacy/privacy.module').then(m => m.PrivacyModule) },
    { path: 'support', loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule) },
    { path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule), canActivate: [AuthenticationGuard] },
    { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) }
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
