import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const currentDate = moment();
    const valueDate = moment(value);
    const days = currentDate.diff(valueDate, 'days') + 1;
    return valueDate.isSame(new Date(), 'days') ? 'Today' : `${days} days ago`;
  }
}
