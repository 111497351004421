import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() linksTemplate?: TemplateRef<any>;

  ngOnInit(): void {
    this.title = this.title;
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
