// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    userPoolWebClientId: '3ieu30jtj71iau7nssarev9hl8',
    userPoolId: 'us-east-1_87FA5FKbk',
    oauth: {
      domain: 'login.civcast.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `https://angular.civcast.com/auth/callback/signin`,
      redirectSignOut: `https://angular.civcast.com/auth/callback/signout`,
      responseType: 'code'
    },
    identityPoolId: 'us-east-1:fa160c41-1834-4557-8c5a-55c342685bd3',
    region: 'us-east-1'
  },
  AuthInfo: {
    authority: 'https://login.civcast.com',
    clientId: '3ieu30jtj71iau7nssarev9hl8',
    api: `https://authtest.civcast.com`,
    logoutUri: `https://angular.civcast.com/auth/callback/signout`,
    redirectUri: `https://angular.civcast.com/validateuser?siteId=5b56242244c03154107e3694`,
    validatedUri: `https://angular.civcast.com/auth/callback/signin`,
    responseType: 'code',
    scope: 'email openid aws.cognito.signin.user.admin',
    state: 'signin',
    refreshTokenName: 'civcast-token',
    accessTokenName: 'access_token'
  },
  SiteLens: {
    BaseUrl: 'https://www.sitelens.co',
    BaseUtilityUrl: 'https://utility.sitelens.co',
    S3: {
      Url: 'https://bucket.sitelens.co/index.php/s/yFZiTjg7rDfrGbv/download',
      Path: '/'
    },
    ApiKey: 'CC4359X345'
  },
  classifiedsRoot: 'https://classifieds.civcast.com/listings/heavyequipment',
  UserManagementServer: `https://stageum.civcast.com`,
  BaseLambdaUrl: 'https://lambda.civcast.com/stage',
  LambdaApiUrl: 'https://lambda.civcast.com',
  lambda_api_endpoint: 'https://lambda.civcast.com',
  lambda_endpoints: {
    users: 'users-stage',
    projects: 'projects-stage',
    bidforecasts: 'bidforecasts-stage',
    global: 'global-stage',
    actions: 'actions-stage',
    auth: 'auth-stage',
    onlinebid: 'onlinebid-stage',
    onlinebid_reporting: 'onlinebid-reporting-stage'
  },
  BaseApiUrl: `https://authtest.civcast.com`,
  BaseApiUrlNoSSL: 'http://authtest.civcast.com',
  BaseSiteUrl: `https://angular.civcast.com`,
  BaseAuthUrl: `https://stageum.civcast.com`,
  CurrentSite: 'CivCast',
  CurrentVersion: { Major: 4, Minor: 0, Patch: 0 },
  SiteType: 0,
  UserMenuName: 'My CivCast',
  GoogleMaps: {
    key: 'AIzaSyDHSVLwRwfphSfOzX-lGmK76duyCgW6Va4',
    libraries: ['geometry', 'visualization']
  },
  GoogleAnalyticsKey: 'UA-6523505-4',
  GrowlTime: 10000,
  TinyMCEKey: '7a5u8jzy8xlfi1sbusadruyq6cd52tduftxk1g3g0a6vg0do'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
