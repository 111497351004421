import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterState, RouterStateSnapshot } from '@angular/router';

import { filter } from 'rxjs/operators';

import { untilDestroyed } from '@core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit, OnDestroy {
  isBidPage = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event: NavigationEnd) => {
        if (event && this.router.url.includes('/bid/')) this.isBidPage = true;
        else this.isBidPage = false;
      });

    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;

    if (snapshot?.url.includes('/bid/')) this.isBidPage = true;
    else this.isBidPage = false;
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
