import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Alert } from '@app/@shared/decorators/alert.decorator';
import { Alerts } from '@app/@shared/fixtures/alerts';

import { UserInfo, Certifications, SettingGroup } from './models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersEndpoint = `${environment.lambda_api_endpoint}/${environment.lambda_endpoints.users}`;
  bidsEndpoint = `${environment.lambda_api_endpoint}/${environment.lambda_endpoints.projects}`;

  constructor(private http: HttpClient) {}

  validateProfile(): Observable<any> {
    const {
      lambda_api_endpoint,
      lambda_endpoints: { auth }
    }: { lambda_api_endpoint: string; lambda_endpoints: { auth: string } } = environment;
    const endpoint = `${lambda_api_endpoint}/${auth}/validateprofile`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }

  getUserInfo(): Observable<UserInfo> {
    const endpoint = `user/GetUserInfo`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }

  getImpersonation(): Observable<any> {
    const endpoint = `user/GetImpersonation`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }

  removeImpersonation(): Observable<any> {
    const endpoint = `user/RemoveImpersonation`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }

  hasRole(role: string): Observable<any> {
    const endpoint = `user/HasRole/${role}`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }

  getUsersCertificationsById(userIds: string[]): Observable<string | Certifications[]> {
    return this.http.post(`${this.usersEndpoint}/getuserscertificationsbyid`, userIds).pipe(
      map((data: Certifications[]) => data),
      catchError(() => of('Error, could not find certifications'))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'Settings Saved',
    dismissable: true
  })
  saveSettings(settings: SettingGroup): Observable<string | any> {
    return this.http.post('user/SaveSettings', settings);
  }

  addDownloadHistory(projectId: string, files: any): Observable<string | Certifications[]> {
    return this.http.put(`user/AddDownloadHistory/${projectId}`, files).pipe(
      map((data: Certifications[]) => data),
      catchError(() => of('Error, could not find bid'))
    );
  }

  checkDownloads(bidId: string): Observable<any> {
    const endpoint = `user/CheckDownloads/${bidId}`;
    return this.http.get(endpoint).pipe(catchError(error => of(error)));
  }
}
