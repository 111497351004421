import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input() collectionSize = 0;
  @Input() pageSize = 50;
  @Input() page = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  changedPage(page: number): void {
    this.pageChange.emit(page);
  }
}
