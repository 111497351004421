import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { cloneDeep, pickBy, omit, isUndefined } from 'lodash';
import * as moment from 'moment';

import { untilDestroyed } from '@app/@core';

import { SharedDataService } from '@app/@shared/services/shared-data.service';

import { LocalStorage } from '@shared/decorators/web-storage.decorator';

import { State, County } from '@shared/models';

import { timeFilterOptions, TimeFilter, timeFilterBooleanOptions } from '@shared/fixtures';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @ViewChild('advancedFilters') advancedFilters: ElementRef;
  @Input() config: any = {
    hasTitle: false,
    hasAdvancedFilters: false,
    hasFilterIcon: false,
    hasDatePickerSuffix: false,
    hasSortSuffix: false,
    sortOptions: [],
    timeOptionsBoolean: false,
    hasTimeInfo: false,
    placeHolder: ''
  };
  @Input() filterOptions: any;
  @Output() filterOptionsChange: EventEmitter<any> = new EventEmitter<any>();
  @LocalStorage HideTBA: boolean;
  timeOptions: TimeFilter;
  stateOptions: State[] = [];
  countyOptions: County[] = [];
  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private sharedDataService: SharedDataService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      UserSearch: null,
      BidDate: { year: 0, month: 0, day: 0 },
      TimeInfo: this.config?.timeOptionsBoolean ? false : 0,
      HideTBA: this.HideTBA,
      search: null,
      sort: null,
      IsReversed: null,
      advanced: this.formBuilder.group({
        ProjectTitle: null,
        StateInfo: null,
        CountyInfo: null,
        ProjectInternalId: null,
        Publisher: null,
        Type: null
      })
    });

    this.timeOptions = this.config?.timeOptionsBoolean ? cloneDeep(timeFilterBooleanOptions) : cloneDeep(timeFilterOptions);

    if (this.config.hasAdvancedFilters) {
      this.getStates();
      this.formGroup.get('advanced.StateInfo').valueChanges.subscribe(state => this.getCounties(state));
    }

    this.formGroup.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(values => {
      const data = {
        ...values,
        ...pickBy(values.advanced),
        // Moment month range starts from 0, NgbDateStruct starts from 1. Hence, one month needs to be subtracted.
        BidDate: (() => {
          if (values.BidDate && !this.config.timeOptionsBoolean) return moment(values.BidDate).subtract(1, 'month').toISOString();
          else if (values.BidDate && this.config.timeOptionsBoolean) {
            const bidDate = moment(values.BidDate).subtract(1, 'month').format('MM-DD-YYYY');
            return bidDate !== 'Invalid date' ? bidDate : null;
          } else return null;
        })(),
        search: (() => {
          if (values?.search && typeof values?.search !== 'string') return moment(values.search).subtract(1, 'month').format('MM/DD/YYYY');
          else return values?.search;
        })(),
        TimeInfo: this.config?.hasTimeInfo ? values?.TimeInfo : null
      };
      this.filterOptionsChange.emit({ ...this.filterOptions, ...omit(data, ['advanced']) });
    });

    // Hide TBA needs to persist on page reloads
    this.formGroup.get('HideTBA').valueChanges.subscribe(HideTBA => (this.HideTBA = HideTBA));
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackByIndex(index: number): number {
    return index;
  }

  clearFilters(event: MouseEvent): void {
    if (event) {
      event.preventDefault();
      this.formGroup.patchValue({
        UserSearch: null,
        BidDate: null,
        TimeInfo: this.config?.hasTimeInfo ? 0 : null,
        HideTBA: this.HideTBA,
        search: null,
        sort: null,
        IsReversed: null,
        advanced: {
          ProjectTitle: null,
          StateInfo: null,
          CountyInfo: null,
          ProjectInternalId: null,
          Publisher: null,
          Type: null
        }
      });
      const isOpen = this.advancedFilters.nativeElement.classList.value.includes('show');
      if (isOpen) this.renderer.removeClass(this.advancedFilters.nativeElement, 'show');
    }
  }

  // States and Counties are needed for Advanced Filters only
  getStates(): void {
    this.sharedDataService
      .getStaticData('states')
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => (this.stateOptions = cloneDeep(data?.States).map(state => ({ ...state, selected: false }))));
  }

  getCounties(state: string): void {
    if (!state) return;

    this.sharedDataService
      .getCountiesByState(state)
      .pipe(untilDestroyed(this))
      .subscribe((counties: County[]) => (this.countyOptions = cloneDeep(counties).map(county => ({ ...county, selected: false }))));
  }

  setSortOrder(sort: any): void {
    const IsReversed = (sort.IsReversed = !sort.IsReversed);
    this.formGroup.patchValue({ sort: sort?.value, IsReversed: !isUndefined(sort.IsReversed) ? IsReversed : null });
  }
}
