import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { untilDestroyed } from '@app/@core';
import { LocalStorage } from '../../../decorators/web-storage.decorator';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';

import { AccountService } from '@app/modules/user/account/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delegate-menu',
  templateUrl: './delegate-menu.component.html',
  styleUrls: ['./delegate-menu.component.css']
})
export class DelegateMenuComponent implements OnInit, OnDestroy {
  @LocalStorage userInfo: any;
  @Input() delegate: any;
  @Output() getData: EventEmitter<any> = new EventEmitter<any>();

  confirmConfig: any = {
    title: 'Delegation Access',
    body: '',
    accept: 'OK',
    reject: 'Cancel'
  };

  constructor(private modalService: NgbModal, private accountService: AccountService, private router: Router) {}

  ngOnInit(): void {
    this.getData.emit(true);
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  removeDelegation(): void {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
    modalRef.componentInstance.config = {
      ...this.confirmConfig,
      body: `Are you sure you want to stop access to ${this.delegate?.CompanyName} account? You can access their account again by going to the delegation area.`
    };
    modalRef.result.then(result => {
      if (result)
        this.accountService
          .removeCurrentDelegation()
          .pipe(untilDestroyed(this))
          .subscribe((delegatedUser: any) => {
            this.userInfo = { ...this.userInfo, CurrentDelegatedUser: null };
            this.getData.emit(true);
          });
    });
  }
}
