import { Component, OnInit, OnDestroy, Self, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() addOn?: string = null;
  private dateValue: Date = new Date();
  private propagateChange = (_: any) => {};

  get date(): Date {
    return this.dateValue;
  }

  set date(value: Date) {
    this.dateValue = value;
    this.propagateChange(this.dateValue);
  }

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.subscribe(value => {
      if (this.dateValue === value) return;
      this.writeValue(value);
    });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: Date): void {
    if (value) this.dateValue = value;
  }
}
