import { Component, OnInit, OnDestroy, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.css']
})
export class CardSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() dataArray: any[] = [];
  private cardValue: any;
  private propagateChange = (_: any) => {};

  get card(): any {
    return this.cardValue;
  }

  set card(value: any) {
    this.cardValue = value;
    this.propagateChange(this.cardValue);
  }

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.subscribe(value => {
      if (this.cardValue === value) return;
      this.writeValue(value);
    });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackByFn(item: any): string {
    return item?.CreditCardId?.$oid;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any): void {
    this.cardValue = value;
  }
}
