import { Component, OnInit, OnDestroy, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Email } from '@app/modules/user/publishers/models';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() dataArray: Email[] = [];
  private propagateChange = (_: any) => {};

  get data(): any[] {
    return this.dataArray;
  }

  set data(value: any[]) {
    this.dataArray = value;
    this.propagateChange(this.dataArray);
  }

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.subscribe(value => {
      if (this.dataArray === value) return;
      this.writeValue(value);
    });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackByFn(item: any): string {
    return item.email;
  }

  addTagFn(email): Email {
    return { id: null, DisplayName: email, Email: email };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: Email[]): void {
    if (value) this.dataArray = value;
  }
}
