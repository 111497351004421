import { Component, OnInit, OnDestroy, Self, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  time = { hour: 12, minute: 0 };
  meridian = true;
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.subscribe(value => {
      if (this.time === value) return;
      this.writeValue(value);
    });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.time = value;
  }

  triggerChange(): void {
    this.onChange(this.time);
  }
}
