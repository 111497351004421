<!-- loader-on-route-change -->
<ngx-loading-bar [includeSpinner]="false" height="6px" color="#fff"></ngx-loading-bar>

<!-- alert -->
<app-alert></app-alert>

<!-- header -->
<app-header [isHomePage]="false"></app-header>

<!-- loader-on-api-call -->
<app-loader></app-loader>

<div [ngClass]="{ container: !isBidPage, 'container-fluid': isBidPage, 'bid-container': isBidPage }">
  <router-outlet></router-outlet>
</div>
