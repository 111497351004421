import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Logger } from '@core';

import { AuthenticationService } from './authentication.service';

import { UnauthorizedModalComponent } from '@app/@shared';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService, private modalService: NgbModal) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.isUserAuthenticated().pipe(
      map((authenticated: boolean) => {
        if (!authenticated) {
          log.debug('Not authenticated');
          this.openUnauthModal();
          return false;
        }
        return authenticated;
      })
    );
  }

  openUnauthModal(): void {
    this.modalService.open(UnauthorizedModalComponent, { size: 'lg' });
  }
}
