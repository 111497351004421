import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor() {}

  serializeFile(file): any {
    file['_t'] = file.Category.SerializeType;
    file.Category['_t'] = ['BaseDataItem', 'BaseFileCategory', 'CivCastFileCategory'];
    file.Storage['_t'] = ['BaseStorage', 'MongoStorage'];
    return file;
  }

  serializeFiles(files): Observable<any> {
    const serFiles = files.map(file => this.serializeFile(file));
    return of(serFiles);
  }
}
