import { Injectable } from '@angular/core';
import { Observable, from, defer } from 'rxjs';

import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor() {}

  logIn(): void {
    const { UserManagementServer, BaseSiteUrl }: { UserManagementServer: string; BaseSiteUrl: string } = environment;
    const userManagementUrl = encodeURI(`${UserManagementServer}/login?redirectUrl=${BaseSiteUrl}/auth/validated`);
    window.location.replace(userManagementUrl);
  }

  register(): void {
    const { UserManagementServer, BaseSiteUrl }: { UserManagementServer: string; BaseSiteUrl: string } = environment;
    const userManagementUrl = encodeURI(`${UserManagementServer}/register?redirectUrl=${BaseSiteUrl}/auth/validated`);
    window.location.replace(userManagementUrl);
  }

  logOut(): void {
    const { UserManagementServer, BaseSiteUrl }: { UserManagementServer: string; BaseSiteUrl: string } = environment;
    const userManagementUrl = encodeURI(`${UserManagementServer}/logout?redirectUrl=${BaseSiteUrl}/auth/callback/signout`);
    window.location.replace(userManagementUrl);
  }

  isUserAuthenticated(): Observable<boolean> {
    const promise = this.checkAuthentication();
    return defer(() => from(promise));
  }

  getAccessToken(): Observable<string> {
    const promise = this.getJWTToken();
    return defer(() => from(promise));
  }

  private async checkAuthentication(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      return false;
    }
  }

  private async getJWTToken(): Promise<string> {
    try {
      const token: CognitoUserSession = await Auth.currentSession();
      return token.getAccessToken().getJwtToken();
    } catch (error) {
      return error;
    }
  }

  getUserInitials(): Observable<string> {
    const promise = this.getUserDetails();
    return defer(() => from(promise));
  }

  private async getUserDetails(): Promise<string> {
    try {
      const token: CognitoUserSession = await Auth.currentSession();
      return `${token.getIdToken()?.payload?.FirstName[0]?.toUpperCase()}${token.getIdToken()?.payload.LastName[0]?.toUpperCase()}`;
    } catch (error) {
      return error;
    }
  }
}
