import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  @Input() message = '';
  @Input() centerTemplate?: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
