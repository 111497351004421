import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgSelectModule } from '@ng-select/ng-select';

// Components
import {
  HeaderComponent,
  DelegateMenuComponent,
  ImpersonationMenuComponent,
  LoaderComponent,
  AlertComponent,
  FiltersComponent,
  GridHeaderComponent,
  PaginationComponent,
  UnauthorizedModalComponent,
  PageTitleComponent,
  ToggleSwitchComponent,
  DatePickerComponent,
  TimePickerComponent,
  MultiSelectComponent,
  ConfirmModalComponent,
  S3FileUploadComponent,
  ProgressBarComponent,
  CardSelectComponent
} from './components';

// Directives
import { AutoFocusDirective, BindQueryParamsDirective, CreditCardNumberDirective } from './directives';

// Pipes
import { SafeHtmlPipe, DurationPipe, FilterByPipe, MomentFormatPipe, NumberFormatPipe } from './pipes';

// Utils
import { MomentDateFormatter } from './utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EditorModule,
    NgbModule,
    NgSelectModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      fullScreenBackdrop: true,
      primaryColour: '#026aa7',
      backdropBorderRadius: '10px'
    })
  ],
  declarations: [
    HeaderComponent,
    DelegateMenuComponent,
    ImpersonationMenuComponent,
    LoaderComponent,
    AlertComponent,
    FiltersComponent,
    GridHeaderComponent,
    PaginationComponent,
    UnauthorizedModalComponent,
    PageTitleComponent,
    ToggleSwitchComponent,
    DatePickerComponent,
    TimePickerComponent,
    MultiSelectComponent,
    ConfirmModalComponent,
    S3FileUploadComponent,
    ProgressBarComponent,
    CardSelectComponent,
    AutoFocusDirective,
    BindQueryParamsDirective,
    CreditCardNumberDirective,
    SafeHtmlPipe,
    DurationPipe,
    FilterByPipe,
    MomentFormatPipe,
    NumberFormatPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EditorModule,
    NgbModule,
    NgSelectModule,
    NgxLoadingModule,
    HeaderComponent,
    DelegateMenuComponent,
    ImpersonationMenuComponent,
    LoaderComponent,
    AlertComponent,
    FiltersComponent,
    GridHeaderComponent,
    PaginationComponent,
    UnauthorizedModalComponent,
    PageTitleComponent,
    ToggleSwitchComponent,
    DatePickerComponent,
    TimePickerComponent,
    MultiSelectComponent,
    ConfirmModalComponent,
    S3FileUploadComponent,
    ProgressBarComponent,
    CardSelectComponent,
    AutoFocusDirective,
    BindQueryParamsDirective,
    CreditCardNumberDirective,
    SafeHtmlPipe,
    DurationPipe,
    FilterByPipe,
    MomentFormatPipe,
    NumberFormatPipe
  ],
  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }]
})
export class SharedModule {}
