import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { map as arrayMap } from 'lodash';

import { AuthComponent } from './auth.component';

const paths: string[] = ['login', 'register', 'logout', 'auth/validated', 'auth/callback/signout'];

const routes: Routes = [...arrayMap(paths, path => ({ path, component: AuthComponent }))];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule {}
