import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify';

import { LocalStorage } from '@app/@shared';

import { AuthenticationService } from './authentication.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {
  isLoading = false;
  @LocalStorage userInfo: any;

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.callByRoute();
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  callByRoute(): void {
    this.isLoading = true;
    const { url }: { url: string } = this.router;
    switch (url) {
      case '/login':
        this.authenticationService.logIn();
        break;
      case '/register':
        this.authenticationService.register();
        break;
      case '/logout':
        this.authenticationService.logOut();
        this.userInfo = undefined;
        break;
      case '/auth/validated':
        Auth.federatedSignIn();
        break;
      case '/auth/callback/signout':
        Auth.signOut();
        this.router.navigate(['/']);
        break;
      default:
        break;
    }
  }
}
