import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Alert } from '../decorators/alert.decorator';
import { Alerts } from '../fixtures/alerts';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AWSService {
  constructor(private http: HttpClient) {}

  getCertificationDownloadUrl(key: string): Observable<string> {
    const headers = { 'Content-Type': 'application/json;charset=UTF-8' };
    return this.http.post('aws/GetCertificationDownloadUrl', key, { headers }).pipe(
      map((data: any) => data?.data),
      catchError(() => of('Error, could not find certification URL'))
    );
  }

  getAWSFileUrl(awsRequest: any): Observable<string> {
    return this.http.post('aws/GetFile', awsRequest).pipe(
      map((data: any) => data?.data),
      catchError(() => of('Error, could not find file URL'))
    );
  }

  getTempFileUrl(fileName: string, fileDownloadName: string, ext: string): string {
    const url = `${environment?.BaseApiUrl}/api/FileStorage/GetTempFile/${fileName}/${fileDownloadName}/${ext}`;
    return url;
  }

  getZipFile(awsRequest: any): Observable<string> {
    return this.http.post('aws/GenerateZipFile', awsRequest).pipe(
      map((data: any) => data?.data),
      catchError(() => of('Error, could not find file'))
    );
  }

  getBidForecastFileUploadPresignedUrl(name: string, type: any): Observable<any> {
    return this.http.post('aws/GeBidForecastFileUploadUrl', { name, type }).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not get file URL'))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'BidForecast File added',
    dismissable: true
  })
  uploadBidForecastFile(url: string, file: any): Observable<any> {
    const headers = { 'Content-Type': file?.type };
    return this.http.put(url, file, { headers, reportProgress: true, observe: 'events' }).pipe(
      map((event: any) => {
        if (!event) return;
        switch (event.type) {
          case HttpEventType.UploadProgress:
            return event;
          case HttpEventType.Response:
            return event.body;
        }
      })
    );
  }

  getUploadPresignedUrl(name: string, type: any): Observable<any> {
    return this.http.post('aws/GetUploadUrl', { name, type }).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not get file URL'))
    );
  }

  uploadDBECertFile(url: string, file: any): Observable<any> {
    const headers = { 'Content-Type': file?.type };
    return this.http.put(url, file, { headers, reportProgress: true, observe: 'events' }).pipe(
      map((event: any) => {
        if (!event) return;
        switch (event.type) {
          case HttpEventType.UploadProgress:
            return Math.round((event.loaded / event.total) * 100);
          case HttpEventType.Response:
            return event.body;
        }
      })
    );
  }
}
