import { debounce } from 'lodash';

/**
 * Adds lodash's debounce to a method to create a debounced function that delays invokin function
 * until after specified milliseconds have elapsed since the last time the debounced function
 * was invoked.
 * @param milliseconds The number of milliseconds to delay.
 * @param options The object to specify lodash debounce options i.e. 'leading', 'maxWait', 'trailing'
 */

export function Debounce(milliseconds: number = 0, options = {}): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const map = new WeakMap();
    const originalMethod = descriptor.value;
    descriptor.value = function (...params): any {
      let debounced = map.get(this);
      if (!debounced) {
        debounced = debounce(originalMethod, milliseconds, options).bind(this);
        map.set(this, debounced);
      }
      debounced(...params);
    };
    return descriptor;
  };
}

/**
 * Debounce can also be achieved using js setTimeout and clearTimeout
 * return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
 *  let timeout = null;
 *  const original = descriptor.value;
 *  descriptor.value = function (...args): any {
 *   clearTimeout(timeout);
 *   timeout = setTimeout(() => original.apply(this, args), milliseconds);
 *  };
 *  return descriptor;
 * };
 */
