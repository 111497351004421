import { Component, OnInit, Input, OnDestroy, TemplateRef } from '@angular/core';

import { untilDestroyed } from '@app/@core';
import { LocalStorage } from '@app/@shared/decorators/web-storage.decorator';

import { AuthenticationService } from '@app/auth/authentication.service';
import { AccountService } from '@app/modules/user/account/account.service';
import { UserService } from '@app/modules/user/user.service';

import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isHomePage = true || undefined;
  @Input() subHeaderTemplate?: TemplateRef<any>;
  @LocalStorage userInfo: any;
  @LocalStorage impersonationInfo: any;
  @LocalStorage isAuthenticated = false;
  @LocalStorage isAdmin: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.authenticationService
      .isUserAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe(authenticated => {
        this.isAuthenticated = authenticated;
        if (authenticated) this.getUserData();
      });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  getUserData(): void {
    this.userService
      .validateProfile()
      .pipe(untilDestroyed(this))
      .subscribe(isValid => {
        if (!isValid?.data) {
          const valURL = encodeURI(`${environment?.UserManagementServer}/profile?redirectUrl=${environment?.BaseSiteUrl}`);
          window.location.replace(valURL);
        }
      });
    this.userService
      .getUserInfo()
      .pipe(untilDestroyed(this))
      .subscribe((userInfo: any) => this.userInfo - { ...userInfo });
    this.userService
      .getImpersonation()
      .pipe(untilDestroyed(this))
      .subscribe(data => (this.impersonationInfo = data ? { ...data } : undefined));
    this.accountService
      .getUnreadNotifications()
      .pipe(untilDestroyed(this))
      .subscribe(res => {});
    this.userService
      .hasRole('admin')
      .pipe(untilDestroyed(this))
      .subscribe(isAdmin => (this.isAdmin = isAdmin?.data));
  }
}
