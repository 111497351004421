<div
  class="ats-switch"
  (click)="toggleSwitch()"
  (keydown)="onKeyPress($event)"
  [ngClass]="{ disabled: isDisabled }"
  role="switch"
  tabindex="0"
>
  <div class="switch-animate" [ngClass]="{ 'switch-off': !status, 'switch-on': status }">
    <span class="switch-left">{{ onLabel }}</span>
    <span class="knob">{{ knobLabel }}</span>
    <span class="switch-right">{{ offLabel }}</span>
  </div>
</div>
