<ng-select [items]="dataArray" [(ngModel)]="card" [clearable]="false">
  <ng-template ng-label-tmp let-item="item">
    <span style="color: black" *ngIf="item?.CardType === 'MasterCard'"
      ><i class="fab fa-2x fa-cc-mastercard masterCard" style="color: #ff5f00"></i
      ><span style="margin-left: 8px">ending in {{ item?.NumberLast4 }}</span></span
    >
    <span style="color: black" *ngIf="item?.CardType === 'Visa'"
      ><i class="fab fa-2x fa-cc-visa visaCard" style="color: #132b97"></i
      ><span style="margin-left: 8px">ending in {{ item?.NumberLast4 }}</span></span
    >
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-clear="clear">
    <i class="fab fa-2x fa-cc-mastercard masterCard" style="color: #ff5f00" *ngIf="item?.CardType === 'MasterCard'"></i>
    <i class="fab fa-2x fa-cc-visa visaCard" style="color: #132b97" *ngIf="item?.CardType === 'Visa'"></i>
    <div>ending in {{ item?.NumberLast4 }}</div>
    <div>{{ item?.NameOnCard }}</div>
    <div>{{ item?.ExpirationMonth }} / {{ item?.ExpirationYear }}</div>
  </ng-template>
</ng-select>
