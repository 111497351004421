<form [formGroup]="formGroup">
  <div class="row mb-3" *ngIf="config?.hasTitle">
    <div class="col-12">
      <span class="font-weight-bold">Filters</span>
    </div>
  </div>

  <div class="row" *ngIf="!config?.hasFilterIcon">
    <div class="col-12 col-md-4">
      <div class="input-group mb-3">
        <input type="text" class="form-control" [placeholder]="config?.placeHolder" formControlName="UserSearch" />
      </div>
    </div>
    <div class="col-12 col-md-4">
      <app-date-picker class="mb-3" [placeholder]="'Bid Date'" formControlName="BidDate"></app-date-picker>
    </div>
    <div class="col-12 col-md-4">
      <div class="form-group">
        <select class="form-control" name="tfSelect" id="tfSelect" formControlName="TimeInfo">
          <option
            *ngFor="let option of timeOptions; let i = index; trackBy: trackByIndex"
            [ngValue]="option?.value"
            [selected]="option?.selected"
          >
            {{ option?.title }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row mb-2" *ngIf="config?.hasAdvancedFilters">
    <div class="col-12 d-flex mb-2" style="font-size: smaller">
      <div class="form-check mr-2">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" formControlName="HideTBA" />Hide TBA
          <span class="badge badge-warning">New</span>
        </label>
      </div>
    </div>
    <div class="col-12 d-flex" style="font-size: smaller" *ngIf="config?.hasAdvancedFilters">
      <a class="mr-3" data-toggle="collapse" href="#collapseCustom" role="button" aria-expanded="false" aria-controls="collapseCustom"
        >Advanced Filters</a
      >
      <a href="javascript:void(0)" (click)="clearFilters($event)">Clear Filters</a>
    </div>
  </div>

  <div class="input-group" *ngIf="config?.hasFilterIcon">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">
        <i class="fas fa-filter" aria-hidden="true"></i>
      </span>
    </div>
    <input class="form-control" [placeholder]="config?.placeHolder" ngbDatepicker #picker="ngbDatepicker" formControlName="search" />
    <div class="input-group-append" *ngIf="config?.hasDatePickerSuffix">
      <button type="button" class="btn input-group-text" (click)="picker.toggle()" ngbTooltip="Select Date">
        <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </div>
    <div class="input-group-append" *ngIf="config?.hasSortSuffix">
      <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-sort-alpha-down" aria-hidden="true"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          *ngFor="let sort of config?.sortOptions; let i = index; trackBy: trackByIndex"
          (click)="setSortOrder(sort)"
        >
          {{ sort?.title }} <span *ngIf="sort?.value === (formGroup?.controls)['sort']?.value">*</span>
        </a>
      </div>
    </div>
  </div>

  <div class="collapse" id="collapseCustom" formGroupName="advanced" #advancedFilters>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="myStateSelect">Name</label>
          <input type="text" class="form-control" formControlName="ProjectTitle" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="StateInfo">State</label>
          <select class="form-control" name="StateInfo" id="StateInfo" formControlName="StateInfo">
            <option
              *ngFor="let option of stateOptions; let i = index; trackBy: trackByIndex"
              [ngValue]="option?.Abbreviation"
              [selected]="option?.selected"
            >
              {{ option?.Abbreviation }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="CountyInfo">County</label>
          <select class="form-control" name="CountyInfo" id="CountyInfo" formControlName="CountyInfo">
            <option
              *ngFor="let option of countyOptions; let i = index; trackBy: trackByIndex"
              [ngValue]="option?.Name"
              [selected]="option?.selected"
            >
              {{ option?.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="ProjectInternalId">ID</label>
          <input type="text" class="form-control" id="ProjectInternalId" formControlName="ProjectInternalId" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="Publisher">Publisher</label>
          <input type="text" class="form-control" id="Publisher" formControlName="Publisher" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="Type">Type</label>
          <input type="text" class="form-control" id="Type" formControlName="Type" />
        </div>
      </div>
    </div>
  </div>
</form>
