<div class="container-fluid" id="delete-access-bar" ng-show="delegate">
  <div class="row bg-warning py-2">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <small>
        You are a delegate in this account: <span>{{ delegate.CompanyName }}</span>
      </small>
      <div>
        <button (click)="removeDelegation()" class="btn btn-danger btn-sm cursor-pointer">Exit Account</button>
      </div>
    </div>
  </div>
</div>
