<div class="container-fluid" id="impersonation-bar" ng-show="impersonation">
  <div class="row bg-danger py-2">
    <div class="col-12 d-flex align-items-center justify-content-between" style="color: white">
      <small>
        <span style="font-weight:700;"> {{ impersonation?.ImpersonatedProfile?.FirstName }} {{ impersonation?.ImpersonatedProfile?.LastName }} ({{ impersonation?.ImpersonatedProfile?.Company?.Name}}).</span>  Original account is: <span style="font-weight:700;">{{ impersonation?.OriginalProfile?.FirstName }} {{ impersonation?.OriginalProfile?.LastName }} ({{ impersonation?.OriginalProfile?.Company?.Name}})</span>
      </small>
      <small class="text-center"> This should only be used in special support situations. </small>
      <div>
        <button (click)="removeImpersonation()" class="btn btn-default btn-sm cursor-pointer">Exit</button>
      </div>
    </div>
  </div>
</div>
