import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private sharedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sharedData$: Observable<any> = this.sharedData.asObservable();
  globalEndpoint = `${environment?.lambda_api_endpoint}/${environment?.lambda_endpoints?.global}`;

  constructor(private http: HttpClient) {}

  modifyData(data): void {
    this.sharedData.next(data);
  }

  getCountiesByState(state: string): Observable<any> {
    return this.http.get(`static/GetCountyByState/${state}`).pipe(
      map((data: any) => data['_v'] as any),
      catchError(() => of('Error, could not find counties'))
    );
  }

  getStaticData(type: string): Observable<string | any[]> {
    return this.http.get(`static/data/${type}`).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not find static data'))
    );
  }

  getGlobalData(dataId: string): Observable<string | any[]> {
    return this.http.get(`${this.globalEndpoint}/getglobaldata?dataId=${dataId}`).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not find global data'))
    );
  }
}
