import { Component, OnInit, OnDestroy } from '@angular/core';
import Amplify from 'aws-amplify';

import { environment } from '@env/environment';
import { Logger } from '@core';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor() {
    Amplify.configure({
      Auth: environment.auth
    });
  }

  ngOnInit(): void {
    // Setup logger
    if (environment.production) Logger.enableProductionMode();

    log.debug('init');
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
