import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  config: any = {
    title: '',
    body: '',
    accept: '',
    reject: ''
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  accept(): void {
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.dismiss();
  }
}
