export interface PublisherBlackListed {
  PublisherId: number;
}

export const publisherBlackList: PublisherBlackListed[] = [
  {
    PublisherId: 85
  },
  {
    PublisherId: 116
  },
  {
    PublisherId: 1973
  },
  {
    PublisherId: 2235
  },
  {
    PublisherId: 13261
  }
];
