<div class="modal-header">
  <h3 class="modal-title">
    Please Sign In
  </h3>
</div>
<div class="modal-body">
  <div>You must sign in to use this feature or view this content.</div>
  <div class="d-flex mt-3">
    <a class="mr-3" [routerLink]="['register']">Register</a>
    <a [routerLink]="['login']">Sign In</a>
  </div>
</div>
