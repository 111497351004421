import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.css']
})
export class GridHeaderComponent implements OnInit, OnDestroy {
  @Input() headers: any = [];
  @Input() currentSort: any = {};
  @Output() currentSortChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackByIndex(index: number): number {
    return index;
  }

  setSortOrder(header: any): void {
    const { SearchItem } = header;
    const IsReversed = (header.IsReversed = !header.IsReversed);
    const update = { SearchItem, IsReversed };
    this.currentSortChange.emit({ ...this.currentSort, ...update });
  }
}
