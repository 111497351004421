import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { untilDestroyed } from '@app/@core';
import { LocalStorage } from '../../../decorators/web-storage.decorator';

import { UserService } from '@app/modules/user/user.service';

@Component({
  selector: 'app-impersonation-menu',
  templateUrl: './impersonation-menu.component.html',
  styleUrls: ['./impersonation-menu.component.css']
})
export class ImpersonationMenuComponent implements OnInit, OnDestroy {
  @LocalStorage impersonationInfo: any;
  @Input() impersonation: any;
  @Output() getData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.getData.emit(true);
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  removeImpersonation(): void {
    this.userService
      .removeImpersonation()
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => {
        console.log(data);
        this.impersonationInfo = undefined;
        this.getData.emit(true);
        this.router.navigate(['bids']);
      });
  }
}
