import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Self } from '@angular/core';
import { FormGroup, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css']
})
export class ToggleSwitchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() isDisabled = false;
  @Input() status = false;
  @Input() onLabel = 'On';
  @Input() offLabel = 'Off';
  knobLabel = '\u00a0';
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.subscribe(value => {
      if (this.status === value) return;
      this.writeValue(value);
    });
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.status = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleSwitch(): void {
    this.status = !this.status;
    this.onChange(this.status);
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.code === 'Space' && !event.altKey && !event.ctrlKey && !event.metaKey) this.toggleSwitch();
  }
}
