import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Alert } from '@app/@shared/decorators/alert.decorator';
import { Alerts } from '@app/@shared/fixtures/alerts';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  usersEndpoint = `${environment.lambda_api_endpoint}/${environment.lambda_endpoints.users}`;

  constructor(private http: HttpClient) {}

  getUnreadNotifications(): Observable<any> {
    const url = `Notification/GetUnreadNotifications/`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getUserNotificationsSearch(filterOptions: any): Observable<any> {
    const { searchType, current, maxItems, search, currentTag } = filterOptions;
    const endPoint = `Notification/GetUserNotificationsSearch/${searchType}/${current}/${maxItems}`;
    const payload = {
      SearchValue: search,
      SearchTag: currentTag
    };
    return this.http.post(endPoint, payload).pipe(catchError(error => of(error)));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'User has been removed and no longer has access to your system.',
    dismissable: true
  })
  deleteDelegateUser(userId: string): Observable<any> {
    return this.http.delete(`user/DeleteDelegateUser/${userId}`).pipe(map((data: any) => data));
  }

  getUsers(search: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json;charset=UTF-8' };
    return this.http.post('user/GetUsers', search, { headers }).pipe(
      map((data: any) => data._v),
      catchError(error => of(error))
    );
  }

  accessDelegateUser(user: any): Observable<any> {
    return this.http.post('user/AccessDelegateUser', user).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'You are no longer using a delegated account.',
    dismissable: true
  })
  removeCurrentDelegation(): Observable<any> {
    return this.http.delete('user/RemoveCurrentDeletgation').pipe(map((data: any) => data));
  }

  addDelegateUser(user: any): Observable<any> {
    return this.http.post('user/adddelegateuser', user).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'User saved successfully.',
    dismissable: true
  })
  saveDelegateUser(user: any): Observable<any> {
    return this.http.post('user/SaveDelegateUser', user).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  getDelegatedPermissions(userId: string): Observable<any> {
    const url = `user/GetDelegatedPermissions/${userId}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  getUsersCertifications(): Observable<any> {
    return this.http.get(`${this.usersEndpoint}/getusercertifications`).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not find certifications'))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'Document added to certification',
    dismissable: true
  })
  addDocumentKeyToCertification(certId: string, docKey: string): Observable<any> {
    return this.http
      .put(`${this.usersEndpoint}/adddockeytocertification?certId=${certId}&docKey=`, docKey)
      .pipe(map((data: any) => data?.data));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'Removed document from certification.',
    dismissable: true
  })
  removeDockeyFromCertification(certId: string, docKey: string): Observable<any> {
    return this.http
      .delete(`${this.usersEndpoint}/removedockeyfromcertification?certId=${certId}&docKey=${docKey}`)
      .pipe(map((data: any) => data?.data));
  }

  addUserCertification(userCertification: any): Observable<any> {
    return this.http.put(`${this.usersEndpoint}/addusercertification`, userCertification).pipe(
      map((data: any) => data?.data),
      catchError(error => of(error))
    );
  }

  removeUserCertification(certId: string): Observable<any> {
    return this.http.delete(`${this.usersEndpoint}/removeusercertification?certId=${certId}`).pipe(
      map((data: any) => data?.data),
      catchError(error => of(error))
    );
  }
}
