import * as creditCard from 'credit-card';

/*
 * Validate credit card form using 'credit-card'
 */

export const determineCardType = (cardNumber, options) => creditCard.determineCardType(cardNumber, options);

export const isValidCardNumber = (cardNumber, type, options) => creditCard.isValidCardNumber(cardNumber, type, options);

export const isValidExpiryMonth = expirationMonth => creditCard.isValidExpiryMonth(expirationMonth);

export const isValidExpiryYear = expirationYear => creditCard.isValidExpiryYear(expirationYear);
