import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { Observable, timer, Subject } from 'rxjs';
import { scan, takeWhile, takeUntil } from 'rxjs/operators';
import { uniqBy } from 'lodash';

import { AlertsService } from '@app/@shared/services/alerts.service';
import { Alert } from '@app/@shared/models';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  animations: [trigger('fade', [transition('void => *', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))])])]
})
export class AlertComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  timer$ = timer(0, 1000).pipe(
    takeUntil(this.destroy$),
    scan(acc => --acc, 11),
    takeWhile(x => x >= 0)
  );
  alerts: Alert[];

  constructor(public alertsService: AlertsService) {}

  ngOnInit(): void {
    this.alertsEnabled.subscribe(alerts => (this.alerts = uniqBy(alerts, 'message')));
  }

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  get alertsEnabled(): Observable<Alert[]> {
    return this.alertsService.setAlertData$;
  }

  trackByIndex(index: number): number {
    return index;
  }

  closeAlert(alert): void {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
    this.destroy$.next(true);
  }
}
