import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private behaviorSubjects: Map<string, BehaviorSubject<any>>;

  constructor(private storage: Storage) {
    this.behaviorSubjects = new Map<string, BehaviorSubject<any>>();
  }

  /**
   * Returns the behaviorSubject by key. If it does not exist, a new one is created but not pre-filled with any value.
   * @param key The Storage key
   */
  private getBehaviorSubject(key: string): BehaviorSubject<any> {
    let behaviorSubject: BehaviorSubject<any> = this.behaviorSubjects.get(key);
    if (!behaviorSubject) {
      behaviorSubject = new BehaviorSubject<any>(null);
      this.behaviorSubjects.set(key, behaviorSubject);
    }

    return behaviorSubject;
  }

  /**
   * Returns the behaviorSubject by key.
   * @param key The Storage key
   */
  public getItem(key: string): BehaviorSubject<any> {
    const behaviorSubject = this.getBehaviorSubject(key);
    const item = this.storage.getItem(key);
    behaviorSubject.next(JSON.parse(item));
    return behaviorSubject;
  }

  /**
   * Stores an item and emits the new value to all its subscribers
   * @param key Storage key
   * @param object the object that should be stored.
   */
  public setItem(key: string, object: any): void {
    const data = JSON.stringify(object);
    this.storage.setItem(key, data);
    this.getBehaviorSubject(key).next(object);
  }

  /**
   * Updates an item and emits the updated value to all its subscribers
   * @param key Storage key
   * @param object the object that should be updated.
   */
  public updateItem(key: string, object: any): void {
    const current = JSON.parse(this.storage.getItem(key));
    const updated = JSON.stringify({ ...current, ...object });
    this.storage.setItem(key, updated);
    this.getBehaviorSubject(key).next(object);
  }

  /**
   * Removes an item from Storage
   * @param key Storage key
   */
  public removeItem(key: string): void {
    this.storage.removeItem(key);
    this.getBehaviorSubject(key).next(null);
  }

  /**
   * Clears the Storage and tells all subscribers of all items that the value is now null.
   */
  public clear(): void {
    this.storage.clear();
    this.behaviorSubjects.forEach((behaviorSubject: BehaviorSubject<any>) => {
      behaviorSubject.next(null);
    });

    console.log(`${this.storage} cleared`);
  }
}
