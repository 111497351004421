import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { Alert } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  // Static members are for the Alerts decorator
  private static setAlertData: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>(null);

  public static alertHolder: Alert[] = new Array<Alert>();
  public setAlertData$: Observable<Alert[]> = AlertsService.setAlertData.asObservable();

  public static showAlert(config: Alert): void {
    AlertsService.alertHolder.push(config);
    AlertsService.setAlertData.next(AlertsService.alertHolder);
  }

  constructor() {}

  get alertsEnabled(): BehaviorSubject<Alert[]> {
    return AlertsService.setAlertData;
  }
}
