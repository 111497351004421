<li class="list-group-item">
  <div class="row font-weight-bold">
    <div
      style="cursor: pointer"
      *ngFor="let header of headers; let i = index; trackBy: trackByIndex"
      class="col-12 col-lg-{{ header?.Size }} d-none d-lg-block"
      (click)="setSortOrder(header)"
    >
      <span>{{ header?.title }}</span>
      <span *ngIf="header?.SearchItem === currentSort?.SearchItem">
        <i class="fas" [ngClass]="{ 'fa-sort-up': header?.IsReversed, 'fa-sort-down': !header?.IsReversed }"></i>
      </span>
    </div>
  </div>

  <div class="col-12 d-lg-none d-flex justify-content-end">
    <div class="dropdown">
      <button
        class="btn btn-white dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-sort-alpha-down"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          *ngFor="let header of headers; let i = index; trackBy: trackByIndex"
          (click)="setSortOrder(header)"
        >
          {{ header?.title }} <span *ngIf="header?.IsDefault">(Default)</span>
          <ng-container *ngIf="header?.SearchItem === currentSort?.SearchItem">
            <i class="fas fa-sort-up" *ngIf="header?.IsReversed"></i>
            <i class="fas fa-sort-down" *ngIf="!header?.IsReversed"></i>
          </ng-container>
        </a>
      </div>
    </div>
  </div>
</li>
