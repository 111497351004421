<div class="input-group">
  <input type="text" class="form-control" [value]="time | momentFormat: 'h:mm A'" readonly />

  <ng-template #timePickerTemplate
    ><ngb-timepicker [(ngModel)]="time" (ngModelChange)="triggerChange()" [meridian]="meridian"></ngb-timepicker
  ></ng-template>
  <div class="input-group-append" [ngbPopover]="timePickerTemplate" [autoClose]="'outside'" placement="bottom">
    <button type="button" class="btn btn-white ccDateBtn">
      <i class="fas fa-clock"></i>
    </button>
  </div>
</div>
