<div class="input-group">
  <div class="input-group-prepend" *ngIf="addOn">
    <span class="input-group-text" id="basic-addon1">{{ addOn }}</span>
  </div>
  <input
    type="text"
    class="form-control"
    readonly
    [placeholder]="placeholder"
    [(ngModel)]="date"
    ngbDatepicker
    #datePicker="ngbDatepicker"
  />
  <div class="input-group-append">
    <button type="button" class="btn input-group-text" (click)="datePicker.toggle()" ngbTooltip="Select Date">
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </button>
  </div>
</div>
