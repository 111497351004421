<div class="progress" style="height: 35px">
  <div
    class="progress-bar progress-bar-striped bg-success"
    role="progressbar"
    style="width: 100%"
    aria-valuenow="100"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    {{ message }}
  </div>
  <div class="text-center" *ngIf="centerTemplate">
    <ng-container [ngTemplateOutlet]="centerTemplate"></ng-container>
  </div>
</div>
