import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unauthorized-modal',
  templateUrl: './unauthorized-modal.component.html',
  styleUrls: ['./unauthorized-modal.component.css']
})
export class UnauthorizedModalComponent implements OnInit, OnDestroy {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
