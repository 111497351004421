import { NewBid } from '@app/modules/user/publishers/models';
import { ObjectID } from 'bson'; // Object Id Generator
import { environment } from '@env/environment';

const date = new Date();

export const defaultBid: NewBid = {
  _t: ['BaseEntity', 'CivCastProject'],
  _id: { $oid: new ObjectID().toString() },
  ProjectTitle: '',
  InternalId: '',
  ReleaseDateTimeInfo: {
    Date: {
      $date: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
    }
  },
  ProjectUniqueId: null,
  Location: {
    State: {
      StateId: 75,
      Name: 'Texas',
      Abbreviation: 'TX'
    },
    County: {
      CountyId: 3412,
      State: 'TX',
      StateId: 75,
      Name: 'Anderson',
      Latitude: 0,
      Longitude: 0
    },
    MapData: null
  },
  Permissions: {
    ShowPlanholdersList: true,
    IsVisible: true,
    IsPlansOfficial: true,
    AllowQuestions: true,
    ShowSiteLens: false,
    IsPrivate: false,
    IsTBA: false,
    ExpireQA: false
  },
  QuestionEmails: [],
  BidDetails: {
    BidDateTimeInfo: {
      TimeZone: {
        ZoneId: 'CST',
        Name: 'CST (Central Standard Time)',
        Value: 'Central Standard Time',
        Offset: null
      }
    },
    Permissions: { IsTBA: false }
  },
  MapView: 'google',
  App: environment?.CurrentSite,
  ProjectType: 0
};
