import { tap, catchError } from 'rxjs/operators';

import { AlertsService } from '../services/alerts.service';

import { Alert } from '../models';
import { Alerts } from '../fixtures';

/**
 * Adds tap and catchError operators to the actual function’s observable.
 * Passes the cofig to the alert service from where it's accessed by the AlertComponent
 * @param config The details to configure the alert
 */
export function Alert(config: Alert): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;

    descriptor.value = function(): any {
      return original.apply(this, arguments).pipe(
        tap(() => {
          const tapConfig = config?.errorMessage ? { ...config, message: config.errorMessage } : config;
          AlertsService.showAlert(tapConfig);
        }),
        catchError(error => {
          const errorConfig: Alert = { type: Alerts.DANGER, message: `Error: ${error?.error?.data || error.message}`, dismissable: true };
          AlertsService.showAlert(errorConfig);
          throw error;
        })
      );
    };
    return descriptor;
  };
}
