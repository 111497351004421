<div class="home-201911 bg-light">
  <!--header (above the fold) -->
  <app-header [isHomePage]="true" [subHeaderTemplate]="subHeaderTemplate"></app-header>

  <!--sub-header-->
  <ng-template #subHeaderTemplate>
    <div class="row justify-content-center py-5">
      <div class="col-12 py-4">
        <div class="text-center tiempos header-first-message text-white pb-2">Civil Construction Bid Management</div>
        <div class="text-center tiempos header-second-message text-white pb-4">
          Advertise, Manage, and Receive Construction Bids and RFQs
        </div>
        <div class="text-center">
          <a class="call-to-action graphik" [routerLink]="['user', 'publishers', 'post-bid']">Post Your Bid</a>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- the stakes -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row">
        <div class="col-12">
          <div class="section-title text-center tiempos">Are you losing hours of time managing bids?</div>
        </div>
        <div class="col-12 col-md-4 mb-5 mb-md-0">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center rounded-circle fa-wrapper mb-4">
              <i class="fas fa-tachometer-fastest text-white fa-3x"></i>
            </div>
            <div class="graphik">Do you have a fast, easy way to communicate bid information with bidders?</div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5 mb-md-0">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center rounded-circle fa-wrapper mb-4">
              <i class="fas fa-clock text-white fa-3x"></i>
            </div>
            <div class="graphik">Does your team spend hours sharing plans, tracking plan holders, and sending addendum alerts?</div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5 mb-md-0">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center rounded-circle fa-wrapper mb-4">
              <i class="fas fa-megaphone text-white fa-3x"></i>
            </div>
            <div class="graphik">Are you losing competitive bids because bidders don't see your advertisements?</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- value-proposition -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row justify-content-center pb-5">
        <div class="col-12">
          <div class="section-title text-center tiempos">Faster, easier bid management.</div>
        </div>
        <div class="col-12">
          <div class="row justify-content-center graphik">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Advertise bids to 10,000+ contractors.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Clearly communicate bid info in one place.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Upload and share plans.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Automatically keep track of plan holders.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Auto send addendum alerts.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Answer questions one time, in one place, with full transparency.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Instant bid results.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Instant bid tabulations.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Eliminate admin work.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>No more phone calls and emails from bidders and bid boards.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Faster, smoother bid openings.</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <div class="d-flex pb-5 align-items-center align-items-sm-start">
                <i class="fas fa-check-circle fa-2x mr-3 check-mark"></i>
                <span>Contractors will love you.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <a class="call-to-action graphik" [routerLink]="['user', 'publishers', 'post-bid']">Post Your Bid</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- guide 1 -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center tiempos">
            We help <a href="javascript:void(0)">hundreds</a> of gov. agencies and civil engineers manage bids and save hours of time.
          </div>
        </div>
        <div class="col-12">
          <div class="text-center">
            <div class="d-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/360.jpg" width="75" height="50" />
            </div>
            <div class="d-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/aark.jpg" width="75" height="50" />
            </div>
            <div class="d-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/browngay.jpg" width="75" height="50" />
            </div>
            <div class="d-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/dannenbaum.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/ehra2.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/freese.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/jonesandcarter.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/ksa.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/logo_IDS.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/logo_LAN.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/logo_pbk.jpg" width="75" height="50" />
            </div>
            <div class="d-none d-sm-inline-block ml-2 mr-2 mb-2 ml-sm-4 mr-sm-4 mb-sm-4 border rounded p-1 bg-white">
              <img src="../../assets/images/logos/logo_vandewiele.jpg" width="75" height="50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- guide 2 -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center tiempos">Read what our <a href="javascript:void(0)">customers</a> say.</div>
        </div>
        <div class="col-12">
          <div class="border p-2 p-sm-5 mb-5">
            <div class="graphik pb-3">
              "What I like most about the software is the ability to quickly communicate information to all plan holders. CIVCAST saves me
              time and money publishing projects for bidding."
            </div>
            <div class="text-muted graphik">-Frank Phelan, Senior Engineer, Jay Engineering Company</div>
          </div>
          <div class="col-12">
            <div class="text-center graphik">
              <a href="https://www.capterra.com/p/183673/AMTEK/reviews/">Read Certified Reviews on Capterra</a>
            </div>
            <div class="text-center">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- the plan -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center tiempos">How to advertise your first bid.</div>
        </div>
      </div>
      <div class="row justify-content-center pb-5">
        <div class="col-12 col-md-4 mb-5 mb-sm-0">
          <div class="text-center d-flex flex-column align-items-center">
            <div class="text-center d-flex justify-content-center align-items-center mb-4 rounded-circle fa-wrapper">
              <i class="fas fa-hand-pointer text-white fa-3x"></i>
            </div>
            <div class="text-center graphik">1. Register and click “Post Your Bid.”</div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5 mb-sm-0">
          <div class="text-center d-flex flex-column align-items-center">
            <div class="text-center d-flex justify-content-center align-items-center mb-4 rounded-circle fa-wrapper">
              <i class="fas fa-keyboard text-white fa-3x"></i>
            </div>
            <div class="text-center graphik">2. Input bid info and set an advertise date.</div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5 mb-sm-0">
          <div class="text-center d-flex flex-column align-items-center">
            <div class="text-center d-flex justify-content-center align-items-center mb-4 rounded-circle fa-wrapper">
              <i class="fas fa-cloud-upload-alt text-white fa-3x"></i>
            </div>
            <div class="text-center graphik">3. Checkout and upload plans.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <a class="call-to-action graphik" [routerLink]="['user', 'publishers', 'post-bid']">Post Your Bid</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- pricing -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center tiempos">Simple pricing. No surprises.</div>
          <div class="text-center grpahik pb-5">Free for bidders.</div>
        </div>
      </div>
      <div class="row justify-content-center graphik">
        <div class="col-12 col-md-4">
          <div class="card mb-4 mb-md-0">
            <div class="card-header text-white text-center" style="background-color: #1026aa">Post a Bid Forecast</div>
            <div class="card-body">
              <h5 class="card-title text-center my-4">Free</h5>
              <div class="small text-muted text-center">Share upcoming bids with 10,000+ contractors.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card mb-4 mb-md-0">
            <div class="card-header text-white text-center" style="background-color: #aa1026">Bid Management</div>
            <div class="card-body">
              <h5 class="card-title text-center my-4">$99.99/bid</h5>
              <div class="small text-muted text-center">Includes all features except e-bidding.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card">
            <div class="card-header text-white text-center" style="background-color: #26aa10">Upgrade to E-Bid</div>
            <div class="card-body">
              <h5 class="card-title text-center my-4">$99.99/bid</h5>
              <div class="small text-muted text-center">Upgrade a regular listing to receive e-bids.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- explanatory paragraph -->
  <section class="container">
    <div class="bg-white white-box">
      <div class="row">
        <div class="col-12">
          <div class="section-title text-center tiempos">Achieve excellence in bid management.</div>
        </div>
        <div class="col-12">
          <div class="border p-2 p-sm-5">
            <p>At CIVCAST we know your organization wants to be great at bid management but also smart with your time and money.</p>
            <p>
              In order to be this way, you need to advertise bids to a large, captive audience so that you receive quality, competitive
              bids. You need a fast, easy way to communicate bid information with plan holders. You need to free-up your team from the
              burden of sharing plans, tracking plan holders, and sending addendum alerts. You need to receive and answer bidder questions
              in one, transparent place. You need smoother bid openings with immediate bid results and instant bid tabulations. And you need
              to make it so easy to bid on your projects that contractors love to work with you.
            </p>
            <p>
              The problem is your current bid management process probably falls short in one of more of these areas, and that makes you
              worry you are losing time, money, competitive bids, or maybe all three. As a small business, we understand what it is like to
              strive for excellence while being mindful of time and money.
            </p>
            <p>
              We believe running a successful organization is hard enough. Bid management should be the thing that just works. That is why
              we created CIVCAST.
            </p>
            <span>
              Here is how it works:
              <ol>
                <li>Register for free and click “Post Your Bid.”</li>
                <li>Input bid info and set an advertise date.</li>
                <li>Checkout and upload plans.</li>
              </ol>
            </span>
            <p>
              This way, you can stop spending so much time on bid management and missing out on competitive bids and start freeing-up your
              team to focus on other things that will make your organization successful.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- video -->
  <!-- footer -->
  <footer class="footer graphik">
    <div class="container-fluid">
      <div class="row py-2">
        <nav class="col-12 d-flex justify-content-center small">
          <div class="mr-3">
            <a class="text-white" href="tel:281-376-4577">281-376-4577</a>
          </div>
          <div class="mr-3">
            <a class="text-white" href="mailto:support@civcast.com">support@civcast.com</a>
          </div>
          <div class="mr-3">
            <a class="text-white" target="_blank" routerLink="terms">Terms</a>
          </div>
          <div class="mr-3">
            <a class="text-white" target="_blank" routerLink="privacy">Privacy</a>
          </div>
        </nav>
      </div>
    </div>
  </footer>
</div>
