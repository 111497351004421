import { Pipe, PipeTransform } from '@angular/core';

import { filter } from 'lodash';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform(value: any, arg?: string): any[] {
    if (!value?.length || !arg?.length) return;

    return filter(value, arg) || [];
  }
}
