import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { ObjectID } from 'bson';

@Component({
  selector: 'app-s3-file-upload',
  templateUrl: './s3-file-upload.component.html',
  styleUrls: ['./s3-file-upload.component.css']
})
export class S3FileUploadComponent implements OnInit, OnDestroy {
  @Input() file: File;
  @Output() fileChange: EventEmitter<any> = new EventEmitter<any>();
  fileId = new ObjectID();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    const file = event && event.item(0);
    this.file = file;
    if (this.file) this.fileChange.emit(this.file);
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Needed for automatic unsubscribe with untilDestroyed
  }
}
