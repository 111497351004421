<div class="alert-top-center">
  <ng-container *ngFor="let alert of alerts; let i = index; trackBy: trackByIndex">
    <ng-container *ngIf="timer$ | async as timer">
      <ngb-alert
        @fade
        class="alert-fixed"
        [type]="alert?.type"
        [dismissible]="alert?.dismissable"
        *ngIf="alert?.message || alert?.alertTemplate"
        (close)="closeAlert(alert)"
      >
        <ng-container *ngIf="alert?.alertTemplate; else alertText"><div [innerHTML]="alert?.alertTemplate"></div></ng-container>
        <ng-template #alertText>
          <span class="alert-message" placement="bottom" [ngbTooltip]="alert?.message">{{ alert?.message }}</span>
        </ng-template>
        <span class="alert-timer">{{ timer }}</span>
      </ngb-alert>
    </ng-container>
  </ng-container>
</div>
