export interface TimeFilter {
  title: string;
  value: number | boolean;
  name: string;
  selected: boolean;
}

export const timeFilterOptions: TimeFilter[] = [
  {
    title: 'Current',
    value: 0,
    name: 'CurrentBids',
    selected: true
  },
  {
    title: 'New Today',
    value: 1,
    name: 'Today',
    selected: false
  },
  {
    title: 'New Yesterday',
    value: 2,
    name: 'Yesterday',
    selected: false
  },
  {
    title: 'New This Week',
    value: 3,
    name: 'Week',
    selected: false
  },
  {
    title: 'New This Month',
    value: 4,
    name: 'Month',
    selected: false
  },
  {
    title: 'Just Bid (In the last 30 days.)',
    value: 5,
    name: 'CurrentBids',
    selected: false
  },
  {
    title: 'All (Archives)',
    value: 6,
    name: 'All',
    selected: false
  }
];

export const timeFilterBooleanOptions: TimeFilter[] = [
  {
    title: 'Current',
    value: false,
    name: 'CurrentBids',
    selected: true
  },
  {
    title: 'All',
    value: true,
    name: 'All',
    selected: false
  }
];
