<header class="container-fluid" [ngClass]="{ hero: isHomePage, 'mb-4': isHomePage }">
  <div class="row" [ngClass]="{ 'bg-civcast-a': !isHomePage }">
    <nav class="col-12 d-flex flex-row justify-content-between align-items-center py-3">
      <div>
        <a [routerLink]="['/']"><img src="../../assets/images/logos/logo.png" width="150" height="26" /></a>
      </div>
      <div class="d-flex flex-row justify-content-end align-items-center" [ngClass]="{ graphik: isHomePage }">
        <a *ngIf="!isAuthenticated" class="mr-3 d-none d-sm-inline-block text-white" [routerLink]="['register']">Create Account</a>
        <a *ngIf="!isAuthenticated" class="mr-3 d-none d-sm-inline-block text-white" [routerLink]="['login']">Sign In</a>
        <a class="mr-3 d-sm-inline-block text-white" [routerLink]="['bids']"> <i class="far fa-search mr-2"></i>Bids </a>
        <div *ngIf="!isAuthenticated" class="dropdown d-sm-none mr-3">
          <button
            class="btn btn-outline-light"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-bars" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" [routerLink]="['bids']">Browse Bids</a>
            <a class="dropdown-item" [routerLink]="['user', 'publishers', 'post-bid']">Post Your Bid</a>
            <a class="dropdown-item" [routerLink]="['login']">Sign In</a>
            <a class="dropdown-item" [routerLink]="['register']">Create Account</a>
          </div>
        </div>
        <a *ngIf="isAuthenticated" class="text-white mr-3" [routerLink]="['user']"><i class="fas fa-bars fa-lg" aria-hidden="true"></i></a>
        <a
          class="call-to-action d-none d-sm-inline-block"
          [ngClass]="{ graphik: isHomePage }"
          [routerLink]="['user', 'publishers', 'post-bid']"
          >Post Your Bid</a
        >
      </div>
    </nav>
  </div>

  <!-- sub-header (only for Home page) -->
  <ng-container *ngIf="isHomePage" [ngTemplateOutlet]="subHeaderTemplate"></ng-container>
</header>

<!-- on-delegate-access -->
<app-delegate-menu
  *ngIf="!isHomePage && userInfo?.CurrentDelegatedUser"
  [delegate]="userInfo?.CurrentDelegatedUser"
  (getData)="getUserData()"
></app-delegate-menu>

<!-- on-impersonation -->
<app-impersonation-menu
  *ngIf="!isHomePage && impersonationInfo"
  [impersonation]="impersonationInfo"
  (getData)="getUserData()"
></app-impersonation-menu>
